import React, { FC, useMemo } from 'react';
import { graphql, Link } from 'gatsby';
import {
  DangerouslySetInnerHtml,
  Layout,
  Container,
  Col,
  Row,
} from 'gatsby-theme-husky/src/layout';
import ProductTitle from 'gatsby-theme-husky/src/components/ProductTitle';
import ProductFeatures from 'gatsby-theme-husky/src/components/ProductFeatures';
import ProductDescription from 'gatsby-theme-husky/src/components/ProductDescription';
import ProductAdvantages from 'gatsby-theme-husky/src/components/ProductAdvantages';
import Button from 'gatsby-theme-husky/src/common/Button';
import ProductCarousel from 'gatsby-theme-husky/src/components/ProductCarousel';
import useScreenRecognition from 'gatsby-theme-husky/src/hooks/useScreenRecognition';
import Breadcrumbs from 'gatsby-theme-husky/src/components/Breadcrumbs';
import ReviewStars from 'gatsby-theme-husky/src/components/ReviewStars';
import ProductReview from 'gatsby-theme-husky/src/components/ProductReview';
import useYotpo from 'gatsby-theme-husky/src/hooks/useYotpo';
import RelatedProductArticle from 'components/relatedProductArticle/relatedProductArticle';
import { IProductPageProps } from './model';
import ETitleProducts from './constants';
import './ProductPageMain.scss';
import './ProductPageRTL.scss';

const ProductPage: FC<IProductPageProps> = ({ data: { umbracoProducts } }) => {
  const {
    defaultCompositions,
    properties: {
      seoMetaTitle,
      seoCanonicalUrl,
      seoMetaDescription,
      seoMetaKeywords,
      seoExternalHreflangs,
      title,
      detailProduct,
      rkt,
      amountCondoms,
      productAdvantages,
      productDescriptionText,
      productFeatures,
      buttons,
      image,
      sku,
      turnBackPDPText,
      buyNowDisclaimer,
      relatedProducts,
      relatedProductsCardCtaButton,
    },
    url,
  } = umbracoProducts;
  const { isMobile } = useScreenRecognition();
  useYotpo();

  const urlToCategoryLink = useMemo(() => {
    const urlToCategory = url.split('/').filter((link) => link);
    urlToCategory.pop();

    return `/${urlToCategory.join('/')}`;
  }, [url]);

  return (
    <Layout
      defaultCompositions={defaultCompositions}
      headerTransparent
      seo={{
        seoMetaDescription,
        seoMetaKeywords,
        seoMetaTitle,
        seoCanonicalUrl,
        seoExternalHreflangs,
      }}
    >
      {isMobile ? (
        <div className="product__breadcrumbs--mobile">
          <Link to={urlToCategoryLink}>
            <div className="icon-arrow-left" />
            <span>{turnBackPDPText}</span>
          </Link>
        </div>
      ) : (
        <Breadcrumbs url={url} className="product__breadcrumbs" />
      )}
      <div className="product-page">
        <div className="product-banner">
          {isMobile && (
            <ProductTitle title={title} amountCondoms={amountCondoms} classes="productTitleOrder" />
          )}
          <ProductCarousel
            isMobile={isMobile}
            images={image}
            isFirstViewport
            classes="product-carousel"
          />
          <div className="product-right-pack">
            {!isMobile && (
              <>
                <ProductTitle
                  title={title}
                  amountCondoms={amountCondoms}
                  classes="productTitleOrder"
                />
                <ReviewStars className="product-reviews" sku={sku} />
              </>
            )}
            <div className="product-buttons">
              {isMobile ? <ReviewStars sku={sku} className="product-reviews" /> : null}
              {buttons?.map((btn, index) => (
                <Button
                  key={`${btn.properties.buttonText}${index + 90}`}
                  ariaLabel={btn.properties.buttonText}
                  link={btn.properties.buttonLink[0].url}
                  btnColorVariant="dark"
                  className="product-buttons-styles"
                >
                  {btn.properties.buttonText}
                </Button>
              ))}
            </div>
            {buyNowDisclaimer ? (
              <DangerouslySetInnerHtml html={buyNowDisclaimer} className="product-disclaimer" />
            ) : null}
          </div>
        </div>
        {detailProduct && (
          <DangerouslySetInnerHtml html={detailProduct} className="productShortDescripcion" />
        )}
        {productFeatures && productFeatures.length > 0 ? (
          <ProductFeatures productFeatures={productFeatures} />
        ) : null}
        {productDescriptionText && productDescriptionText.length > 0 ? (
          <ProductDescription productDescriptionText={productDescriptionText} />
        ) : null}
      </div>
      {productAdvantages && productAdvantages.length > 0 ? (
        <ProductAdvantages productAdvantages={productAdvantages} />
      ) : null}
      <ProductReview sku={sku} />
      <small className="product-page-veeva">{rkt}</small>
      <div className="related-products__wrapper">
        <Container className="products__container">
          <Row className="line__top">
            <Col lg="11" className="cards__container">
              {relatedProducts ? <h3 className="title-container">{ETitleProducts.title}</h3> : null}
              <div className="article__container">
                {relatedProducts && relatedProducts.length > 0
                  ? relatedProducts.map(({ properties: product }) => (
                      <RelatedProductArticle
                        {...product}
                        textButton={relatedProductsCardCtaButton}
                      />
                    ))
                  : null}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div
        className="yotpo yotpo-main-widget"
        data-product-id={sku}
        data-name={title}
        data-url={url}
      />
    </Layout>
  );
};
export const query = graphql`
  query($url: String!, $links: [String]) {
    umbracoProducts(url: { eq: $url }) {
      url
      defaultCompositions {
        ...DefaultCompositionsFragment
      }
      properties {
        seoMetaDescription
        seoMetaKeywords
        seoMetaTitle
        seoCanonicalUrl
        seoExternalHreflangs {
          hreflang: key
          href: value
        }
        image {
          properties {
            imageAltLabel
          }
          mobileImage: localImage {
            childImageSharp {
              fluid(maxWidth: 200, quality: 50) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          desktopImage: localImage {
            childImageSharp {
              fluid(maxWidth: 420, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        sku
        title
        detailProduct
        rkt
        amountCondoms
        turnBackPDPText
        productAdvantages {
          properties {
            productAdvantage
            productAdvantageComment
          }
        }
        buttons {
          properties {
            buttonText
            buttonLink {
              url
            }
          }
        }
        productDescriptionText {
          properties {
            descriptionText
            descriptionTitle
          }
        }
        productFeatures {
          properties {
            productFeaturesText {
              value
            }
            productTextTitle
          }
        }
        buyNowDisclaimer
        relatedProductsCardCtaButton
        relatedProducts {
          properties {
            title
            description
            cta {
              name
              url
            }
            image {
              altText
              fallbackUrl
            }
          }
        }
      }
    }
    relatedProducts: allUmbracoProducts(filter: { url: { in: $links } }) {
      nodes {
        url
        properties {
          rkt
          detailProduct
          image {
            properties {
              imageAltLabel
            }
            localImage {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          title
        }
      }
    }
  }
`;

export default ProductPage;
